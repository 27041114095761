import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/index";
import SEO from "../components/SEO";
import CourseCard from "../components/CourseCard";
import MailChimpBanner from "../components/Mailchimp-banner";
// import promoBanner from "../../src/images/blackFridayBanner.png";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
// import PromotionPopup from "../components/PromotionPopup";
import LHTBanner from "../components/LHTBanner";
import PromoBanner from "../components/PromoBanner";
import ProductItem from "../components/ProductItem";

const Learning = ({ data }) => {
  // only get the mdx posts of category Courses
  const mdxCourses = data.allMdx.edges.filter(
    (post) => post.node.frontmatter.category === "Courses"
  );
  const courses = [];

  const shopItems = data.allShopJson.edges;
  const filteredShopItems = shopItems.filter((item) => !item.node.noIndex);

  for (const { node: course } of data.allCoursesJson.edges) {
    // make sure that the order is based on the order in the json file not the markdown files
    const matchingCourse = mdxCourses.find(
      (post) => post.node.frontmatter.detailPagePath === course.detailPagePath
    );
    if (matchingCourse) {
      courses.push(matchingCourse);
    }
  }

  return (
    <Layout bodyclassName="page-services">
      <SEO
        title={
          "Vietnamese Online Courses & eBooks | Learn Southern Vietnamese with HowToVietnamese"
        }
      />
      {/* <PromotionPopup /> */}
      <PromoBanner />

      <div className="contentContainer jumbotron jumbotron-fluid margin-from-header box-shadow">
        {/* <OutboundLink
          href="https://store.howtovietnamese.com/?coupon=50BLACKFRIDAY"
          target="_blank"
        >
          <img
            src={promoBanner}
            className="img-fluid mx-auto d-block"
            alt="Black Friday Banner"
          ></img>
        </OutboundLink> */}
        <div className="container">
          <div className="col-12 mr-1 pt-0 pt-md-0 ml-md-4">
            <h2>Learning</h2>
            <p className="text-muted">
              Language lessons and books that are carefully designed to help you
              learn and speak Vietnamese naturally.
            </p>
          </div>
        </div>
      </div>

      <div className="container pb-0 pb-md-2 d-flex justify-content-center pr-md-0 pl-md-0 mt-6 mt-md-8">
        <h2>Courses</h2>
      </div>
      <div className="container pb-2 d-flex justify-content-center pr-md-0 pl-md-0">
        <div className="col-12 col-md-11 pl-2 pr-2">
          {/*COURSES */}
          {courses.length ? (
            <div className="row ">
              <div className="col-12"></div>
              <div className="row">
                {courses.map((edge, i) => (
                  <div className="col-sm-12 col-md-4 mb-md-4">
                    <CourseCard edge={edge} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* END COURSES*/}
      {/* shop items */}
      <div className="container mt-6 pb-0 pb-md-2 d-flex justify-content-center pr-md-0 pl-md-0">
        <h2>Books</h2>
      </div>
      <div className="container pb-2 d-flex justify-content-center pr-md-0 pl-md-0">
        <div className="col-12 col-md-11">
          <div className="row justify-content-left">
            {filteredShopItems.map((item) => (
              <div className="col-12 col-md-4 pb-md-4">
                <ProductItem props={item}></ProductItem>
              </div>
            ))}
          </div>
        </div>
        {/* END SHOP ITEMS */}
      </div>

      <div className="col-12 no-bg">
        <MailChimpBanner />
      </div>
    </Layout>
  );
};

export default Learning;

export const query = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            path
            detailPagePath
            title
            price
            subtitle
            category
            level
            excerpt
            date(formatString: "DD MMMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allCoursesJson {
      edges {
        node {
          coursePath
          detailPagePath
          extraInfo
          price
          type
          purchaseLinks {
            label
            link
            isOutbound
          }
        }
      }
    }

    allShopJson {
      edges {
        node {
          title
          description
          image
          detailPagePath
          price
        }
      }
    }
  }
`;
